import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import {
  FaAt,
  FaBuilding,
  FaGithub,
  FaHashtag,
  FaLinkedin,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";

const Layout: React.FC = ({ children }) => {
  return (
    <div>
      <nav className="from-slate-800 to-blue-800 bg-gradient-to-br">
        <div className="max-w-6xl mx-auto px-2 text-white flex">
          <StaticImage
            placeholder="none"
            height={56}
            width={61}
            src="../images/tomphill-ltd-logo.png"
            alt="Logo"
          />
          <div className="ml-4 font-heading mt-1">
            <div className="font-bold text-xl uppercase">TomPhill Ltd</div>
            <div className="text-slate-400 -mt-2 text-lg font-bold">
              React &amp; Gatsby developer
            </div>
          </div>
        </div>
      </nav>
      <main className="font-body max-w-6xl mx-auto my-5">{children}</main>
      <footer className="from-slate-800 to-blue-800 bg-gradient-to-tl">
        <div className="max-w-6xl mx-auto p-6 text-white md:flex font-body">
          <div className="md:flex-1">
            <div className="flex">
              <FaGithub className="my-auto" />
              <strong className="font-bold my-auto ml-2">GitHub</strong>
            </div>
            <a
              href="https://github.com/tomphill/"
              target="_blank"
              rel="nofollow noreferrer"
              className="text-blue-300 text-sm"
            >
              https://github.com/tomphill
            </a>
            <div className="flex mt-4">
              <FaLinkedin className="my-auto" />
              <strong className="font-bold my-auto ml-2">LinkedIn</strong>
            </div>
            <a
              href="https://www.linkedin.com/in/tomemyrphillips/"
              target="_blank"
              rel="nofollow noreferrer"
              className="text-blue-300 text-sm"
            >
              https://www.linkedin.com/in/tomemyrphillips
            </a>
            <div className="flex mt-4">
              <FaYoutube className="my-auto" />
              <strong className="font-bold my-auto ml-2">YouTube</strong>
            </div>
            <a
              href="https://youtube.com/c/webdeveducation"
              target="_blank"
              rel="nofollow noreferrer"
              className="text-blue-300 text-sm"
            >
              https://youtube.com/c/webdeveducation
            </a>
            <div className="flex mt-4">
              <FaWhatsapp className="my-auto" />
              <strong className="font-bold my-auto ml-2">
                Mobile / Whatsapp
              </strong>
            </div>
            <a
              href="tel:+44 7702 985 364"
              target="_blank"
              rel="nofollow noreferrer"
              className="text-blue-300 text-sm"
            >
              +44 7702 985 364
            </a>
            <div className="flex mt-4">
              <FaAt className="my-auto" />
              <strong className="font-bold my-auto ml-2">Email</strong>
            </div>
            <a
              href="mailto:info@tomphill.co.uk"
              target="_blank"
              rel="nofollow noreferrer"
              className="text-blue-300 text-sm"
            >
              info@tomphill.co.uk
            </a>
          </div>
          <div className="mt-4 md:mt-0 md:flex-1 md:text-right">
            <div className="flex">
              <strong className="font-bold my-auto mr-2 md:ml-auto">
                Address
              </strong>
              <FaBuilding className="my-auto" />
            </div>
            <div className="text-slate-400">
              <div>TomPhill Ltd</div>
              <div>Glaneigion</div>
              <div>Glanmor Terrace</div>
              <div>New Quay</div>
              <div>Ceredigion</div>
              <div>SA45 9PS</div>
            </div>
            <div className="mt-4 flex">
              <strong className="font-bold my-auto mr-2 md:ml-auto">
                Company number
              </strong>
              <FaHashtag className="my-auto" />
            </div>
            <div className="text-slate-400">09531652</div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
